// Define a function called Validation that takes in an object called values as a parameter
function Validation(passcode, category) {
  let error = {};

  if (passcode === "") {
    error.passcode = "Escribe tu clave de acceso";
  } else {
    error.passcode = "";
  }

  if (category === "") {
    error.categoria = "Elige una de las categorías";
  } else {
    error.categoria = "";
  }

  return error;
}

export default Validation;
