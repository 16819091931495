import React, { useEffect, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"; // React routing modules

import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";

import { Link } from "react-router-dom";


const DocumentList = () => {
  
  const [searchParams] = useSearchParams(); // Defining searchParams state variable (for getting search params from URL)
  const navigate = useNavigate(); // Getting navigate from react-router-dom (for navigating between pages)  

  const team = searchParams.get("equipo"); // Getting equipo from URL
  const categoria = searchParams.get("categoria"); // Getting categoria from URL


  const handleSubmit = event => {
    event.preventDefault();

    const data = new FormData(event.target);
    
    const filename = data.get("filename").name;
    const url = `https://back.smartcoach.top/uploadPDF.php?dir=datos/${team}/${encodeURIComponent(categoria)}&filename=${encodeURIComponent(filename)}`;

    fetch(url, {
      method: "POST",
      body: data,
    })
      .then(response => {});

    fetchPDFs();
  };

  const handleDelete = pdfname => {
      fetch(`https://back.smartcoach.top/delPDF.php?dir=datos/${team}/${encodeURIComponent(categoria)}&filename=${encodeURIComponent(pdfname)}`)
        .then(res => console.log(res));
  };

  const [list, setList] = useState([]);

  const fetchPDFs = useCallback(() => {
      fetch(
          `https://back.smartcoach.top/getPDFList.php?dir=datos/${team}/${encodeURIComponent(categoria)}`)
        .then(response => response.text())
        .then(res => {
            const parsed = JSON.parse(res);
            if (parsed !== false) {
                setList(parsed);
            }
        });
  }, [team, categoria]);

  useEffect(() => {
      fetchPDFs();
  }, [fetchPDFs]);

  return (
    <div>
      <h1>Documentos {team}</h1>
      <h2>Categoria {categoria}</h2>
      <button onClick={() => navigate(-1)}>Volver</button>

      <form onSubmit={handleSubmit}>
        <input type="file" id="filename" name="filename"/>
        <input type="submit" value="Subir documento"/>
      </form>

      <button onClick={() => fetchPDFs()}>Refrescar lista</button>

      <div id="pdf-list">
        {list.map(pdf => {
                if (!pdf.startsWith(".")) {
                    return (
                        <div key={pdf}>
                            <a href={"https://smartcoach.top/back/datos/" + team + "/" + categoria + "/" + pdf} target="blank">
                                {pdf}
                            </a>
                            <button onClick={() => handleDelete(pdf)}>Borrar</button>
                        </div>
                    );
                }
            }
        )}
      </div>
    </div>
  );
};

export default DocumentList;
