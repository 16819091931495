import "./PlayGenerator.css"; // CSS file

// Defining the PlayGenerator component (Godot game)
function PlayGenerator() {
    return (
        <iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2209416">
            <a href="https://erizux.itch.io/smart-coach">Smart Coach by Erizux</a>
        </iframe>
    );
}
export default PlayGenerator;
